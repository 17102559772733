export class AppSettings {

  public static IS_IE_OR_EDGE = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);


  //------------------------------------------------------------------
  public static APP_START_YEAR = 2018;
  public static DEFAULT_PAGER_COUNT = 50;
  public static DEFAULT_QUERY_LIMIT = 100000;
  public static Session_Expire_Message = "Session Expired";
  public static Session_Expire_Description = "Kindly login";
/******************************************************************************/
  //Local

  // public static API_ENDPOINT ='http://localhost:4400/api/v1';
  // public static IMAGE_BASE_URL = 'http://localhost:4400/files/';
  // public static SCREENSHOT_BASE_URL = 'https://d2del47p5ovamj.cloudfront.net/';
  // public static AI_CHAT_URL = 'https://staging-chat.insiderscorp.com';
/******************************************************************************/
  //Staging

  public static API_ENDPOINT ='https://staging-api.insiderscorp.com/api/v1';
  public static IMAGE_BASE_URL = 'https://staging-api.insiderscorp.com/files/';
  public static SCREENSHOT_BASE_URL = 'https://d2del47p5ovamj.cloudfront.net/';
  public static AI_CHAT_URL = 'https://staging-chat.insiderscorp.com';
/******************************************************************************/
  //Production

  // public static API_ENDPOINT ='https://api.insiderscorp.com/api/v1';
  // public static IMAGE_BASE_URL = 'https://api.insiderscorp.com/files/';
  // public static SCREENSHOT_BASE_URL = 'https://d1br57uxtwv17b.cloudfront.net/';
  // public static AI_CHAT_URL = 'https://chat.insiderscorp.com';

/******************************************************************************/
}
